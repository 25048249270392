<template>
  <div :class="{ 'animate__animated animate__pulse animate__infinite': warning }">
    <span :class="{ warning: warning }">{{ time | prettify }}</span>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      default: ''
    },
    warning: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    prettify: function(value) {
      let data = value.split(':')
      let minutes = data[0]
      let secondes = data[1]
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (secondes < 10) {
        secondes = '0' + secondes
      }
      return minutes + ':' + secondes
    }
  },
  watch: {
    time() {}
  }
}
</script>

<style lang="scss" scoped>
.warning {
  color: #dc3545;
}
</style>
