import i18n from '@/i18n'

export const policyTypes = {
  options: [
    { id: 1, name: 'Rent Smart' },
    { id: 2, name: 'Home Plus' },
    { id: 3, name: 'Car Protect' }
  ]
}

export const claimDecisions = [
  { key: 1, name: i18n.t('lodgements.claim-decision-accept'), value: true, variant: 'success' },
  { key: 0, name: i18n.t('lodgements.claim-decision-deny'), value: false, variant: 'danger' }
]

export const isTeamLeaderRequired = [
  { key: 1, name: 'Yes', value: true, variant: 'success' },
  { key: 0, name: 'No', value: false, variant: 'danger' }
]
