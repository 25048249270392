import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Common/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import { policyTypes, claimDecisions } from '@/data/formContents'
import { errorMessages } from '@/utils/validators'
import moment from 'moment'
export const formMixin = {
  components: {
    BaseInput,
    BaseSelection,
    ProcessingButton
  },
  data() {
    return {
      loading: false,
      errorMessages,
      processing: false,
      formChanged: false,
      policyTypes,
      claimDecisions
    }
  },
  methods: {
    setError(item, name) {
      if (item.$dirty) {
        let self = this
        let errorMessage = ''
        let errorMessages = self.errorMessages
        if (item.required === false) {
          errorMessage = `${name} ${errorMessages.required}`
          return errorMessage
        }

        if (item.nameMinLength === false) {
          errorMessage = `${name} ${errorMessages.nameMinLength}`
          return errorMessage
        }

        if (item.email === false) {
          errorMessage = `${name} ${errorMessages.email}`
          return errorMessage
        }

        if (item.emailMinLength === false) {
          errorMessage = `${name} ${errorMessages.emailMinLength}`
          return errorMessage
        }

        if (item.passwordMaxLength === false) {
          errorMessage = `${name} ${errorMessages.passwordMaxLength}`
          return errorMessage
        }

        if (item.passwordMinLength === false) {
          errorMessage = `${name} ${errorMessages.passwordMinLength}`
          return errorMessage
        }

        if (item.isPasswordValid === false) {
          errorMessage = `${errorMessages.isPasswordValid}`
          return errorMessage
        }

        if (item.samePassword === false) {
          errorMessage = `${name} ${errorMessages.samePassword}`
          return errorMessage
        }
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss.000')
    },
    async onItemSave(action, actionItem, successMessage) {
      let self = this
      await self.$store
        .dispatch(action, actionItem)
        .then((response) => {
          self.processing = false
          self.formChanged = false
          self.$notify(response.status, successMessage, response.message, {
            duration: 2000,
            permanent: false
          })
          self.$emit('itemSaved', true)
        })
        .catch((error) => {
          self.processing = false
          self.$notify('error', error, null, {
            duration: 3000,
            permanent: false
          })
          NProgress.done()
        })
    },
    getNameInitials(fullName) {
      return fullName
        .split(' ')
        .map((n) => n[0])
        .join('')
    },
    convertLocalTimestamp(date) {
      return moment.utc(date).local().format('X')
    }
  }
}
